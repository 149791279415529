import { Select } from '@components'
import { Input, Button, Tooltip } from '@elements'
import {
  useGraphqlCheckout,
  useGraphqlPurchaseRequest,
  useLocationWithState,
  useUrlSearchParams,
  useWindowSize
} from '@hooks'
import BackArrow from '@images/back-arrow.svg'
import PriceTagIcon from '@images/price-tag.svg'
import { CheckoutLayout } from '@layouts'
import { golfClubModel as graphqlGolfClubModel, golfEquipment as graphqlGolfEquipment } from '@graphql'
import { AddressCard, GolfClubModel, GolfEquipmentModel, PaymentCard, ProductPageItemType } from '@types'
import { addDays } from '@utils'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'

interface CheckoutSetBuyPriceProps {
  id: string
  type: 'golfClub' | 'equipment'
}

const EXPIRATION_DAYS = [
  { label: '48 Hours', value: '2' },
  { label: '5 Days', value: '5' },
  { label: '10 Days', value: '10' },
  { label: '20 Days', value: '20' },
  { label: '30 Days', value: '30' },
  { label: 'Good ‘Til Cancelled', value: 'null' }
]

const CheckoutSetBuyPrice: React.FC<CheckoutSetBuyPriceProps> = ({ id, type }) => {
  const { createPurchaseRequest, loading: createPurchaseRequestLoading } = useGraphqlPurchaseRequest()
  const { state } = useLocationWithState<{ viewPage: boolean; type: ProductPageItemType }>()
  const isGolfClubPage = state?.type === 'GolfClubModel'
  const [golfModel, setGolfModel] = useState<GolfEquipmentModel | GolfClubModel>()
  const isBackPageView = state?.viewPage
  const writtenPrice = isBackPageView ? 0 : parseInt(localStorage.getItem('writtenPrice') || '0')
  const [newGolfClubPrice, setNewGolfClubPrice] = useState<number | undefined>(writtenPrice)
  const [selectedExpirationDays, setSelectedExpirationDays] = useState<string>(EXPIRATION_DAYS[0].value)
  const { toRender: isMobileRender } = useWindowSize(['mobile', 'landscape'])
  const { golfType, golfTypeData, loading } = useGraphqlCheckout(id, type)
  const { getUrlSearchParam } = useUrlSearchParams('promoCode')
  const promoCodeId = getUrlSearchParam()

  useEffect(() => {
    if (golfType) {
      if ('golfClubModel' in golfType) setGolfModel(golfType.golfClubModel)
      else if ('golfEquipmentModel' in golfType) setGolfModel(golfType.golfEquipmentModel)

      if (golfModel && golfModel.lowestSellPrice && !newGolfClubPrice) {
        setNewGolfClubPrice(golfModel.lowestSellPrice)
      }
    }
  }, [golfType])

  const handleConfirm = async (addressCard?: AddressCard, userCard?: PaymentCard) => {
    const expirationDaysNumber = selectedExpirationDays !== 'null' ? +selectedExpirationDays : selectedExpirationDays
    const expiresAt =
      expirationDaysNumber !== 'null' ? addDays(new Date(), expirationDaysNumber).toISOString().split('T')[0] : null
    const price = newGolfClubPrice
    const returnPayload = () => {
      if (golfType && price && addressCard && userCard) {
        if (golfModel?.__typename === 'GolfClubModel')
          return {
            addressCardId: addressCard.id,
            expiresAt,
            golfClubTypeId: golfType?.id,
            price,
            paymentCardId: userCard.id,
            promoCodeId: promoCodeId
          }

        return {
          addressCardId: addressCard.id,
          expiresAt,
          golfEquipmentTypeId: golfType?.id,
          price,
          paymentCardId: userCard.id,
          promoCodeId: promoCodeId
        }
      }
    }

    const payload = returnPayload()
    const refetchQuery = isGolfClubPage
      ? graphqlGolfClubModel.FetchGolfClubModel
      : graphqlGolfEquipment.FetchGolfEquipmentModel

    if (payload)
      await createPurchaseRequest(
        {
          purchaseRequest: payload
        },
        {
          refetchQueries: [
            {
              query: refetchQuery,
              variables: { id: golfModel?.id || '' }
            }
          ]
        }
      )
  }

  /**
   * if a tourdayValue is set it will return that value, otherwise it will attempt to
   * calculate the retailPrice * the tourdayPercent
   * worst case it returns 0
   *
   * @param format will format it nicely for display, otherwise it can be used for calculations
   * @returns value || 0
   */
  const getTourdayValue = (format = false) => {
    let value = 0
    let retailPrice = 0
    let tourdayPercent = 0.0
    if (golfType && 'golfClubModel' in golfType) {
      value = golfType.golfClubModel.tourdayValue
      retailPrice = golfType.golfClubModel.retailPrice
      tourdayPercent = golfType.golfClubModel.tourdayRetailPricePercent
    } else if (golfType && 'golfEquipmentModel' in golfType) {
      value = golfType.golfEquipmentModel.tourdayValue
      retailPrice = golfType.golfEquipmentModel.retailPrice
      tourdayPercent = golfType.golfEquipmentModel.tourdayRetailPricePercent
    }

    // if no value then default to the retail price * tourdayPercent
    if ((!value || value === 0) && tourdayPercent) {
      value = retailPrice * tourdayPercent
    }
    if (format) {
      return value ? `$${value.toLocaleString()}` : 0
    }
    return value ?? 0
  }

  // format will format it nicely for display, otherwise it can be used for calculations
  const getBuyItNowPrice = (format = false) => {
    const prices: number[] = []
    if (golfType && 'golfClubModel' in golfType) {
      golfType.golfClubsOnSale.forEach(i => {
        prices.push(i.price)
      })
    } else if (golfType && 'golfEquipmentModel' in golfType) {
      golfType.golfEquipmentsOnSale.forEach(i => {
        prices.push(i.price)
      })
    }
    if (format) {
      return prices.length > 0 ? `$${Math.min(...prices).toLocaleString()}` : 'None'
    }
    return prices.length > 0 ? Math.min(...prices) : 0
  }

  const suggestedPercents = [5, 10, 20]
  const handleSetSuggested = (percent: number) => {
    const price = getBuyItNowPrice() !== 0 ? getBuyItNowPrice() : getTourdayValue()
    if (typeof price === 'number') {
      setNewGolfClubPrice(Math.ceil(price - price * (percent / 100)))
    }
  }

  return (
    <CheckoutLayout
      golfTypeData={golfTypeData}
      golfTypeLoading={loading}
      handleConfirm={handleConfirm}
      price={newGolfClubPrice}
      title={
        <strong className="checkout-payment-info-title subtitle1">
          {isMobileRender && <BackArrow onClick={() => navigate(-1)} />}
          <PriceTagIcon />
          <span style={{ fontWeight: 600 }}>MAKE OFFER</span>
        </strong>
      }
      mutationLoading={createPurchaseRequestLoading}
    >
      <div className={'checkout-payment-note'}>
        You are only charged if your offer is accepted! Choose an expiration date for your offer and cancel anytime. We
        will notify you via email if your offer is accepted. There are no fees for buyers.
      </div>
      {(getBuyItNowPrice() !== 0 || getTourdayValue() !== 0) && (
        <div className={'checkout-payment-make-offer-suggested'}>
          {getBuyItNowPrice() !== 0 && (
            <div className={'checkout-payment-make-offer-suggested-info'} style={{ color: '#B88CFF' }}>
              Buy it Now Price: {getBuyItNowPrice(true)}
            </div>
          )}
          {getBuyItNowPrice() === 0 && getTourdayValue() !== 0 && (
            <div className={'checkout-payment-make-offer-suggested-info'} style={{ color: '#B88CFF' }}>
              Suggested Offer Price: {getTourdayValue(true)}
            </div>
          )}
          <div className={'checkout-payment-make-offer-suggested-percents'}>
            {suggestedPercents.map(percent => {
              const buttonText = `- ${percent}%`
              return (
                <Button size={'lg'} type={'roundBlack'} key={percent} onClick={() => handleSetSuggested(percent)}>
                  {buttonText}
                </Button>
              )
            })}
            {getBuyItNowPrice() !== 0 && (
              <Tooltip>We recommend making an offer between 5-20% less than the buy it now price.</Tooltip>
            )}
            {getBuyItNowPrice() === 0 && getTourdayValue() !== 0 && (
              <Tooltip>We recommend making an offer between 5-20% less than the Suggested Offer Price.</Tooltip>
            )}
          </div>
        </div>
      )}
      <div className="checkout-payment-info-set-price-form">
        <div className="checkout-payment-info-set-price-form-field">
          <label>your offer</label>
          <Input
            placeholder="$0.00"
            // value={newGolfClubPrice?.toString() === '0' ? ' ' : newGolfClubPrice?.toString()}
            value={newGolfClubPrice?.toString()}
            onChange={val => {
              if (+val < 1000000) {
                setNewGolfClubPrice(+val)
              }
            }}
            type="text"
            theme="green"
          />
          <div className={'checkout-payment-info-set-price-form-field-warn'}>
            *You must meet the minimum offer of $15
          </div>
        </div>
        <div className="checkout-payment-info-set-price-form-field">
          <label>expiration</label>
          <Select
            name="expiration"
            options={EXPIRATION_DAYS}
            value={selectedExpirationDays}
            onChange={val => val && setSelectedExpirationDays(val)}
          />
        </div>
      </div>
    </CheckoutLayout>
  )
}

export default CheckoutSetBuyPrice
